<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="justificationproposalData === undefined">
      <h4 class="alert-heading">Errore dati Proposta Ferie</h4>
      <div class="alert-body">
        Nessuna Proposta Ferie trovata con questo ID. Controlla
        <b-link class="alert-link" :to="{ name: 'apps-justificationproposals-list' }"> Lista Proposte Ferie </b-link>
        per altre Proposte Ferie.
      </div>
    </b-alert>

    <template v-if="justificationproposalData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <justificationproposals-view-justificationproposal-info-card :justificationproposal-data="justificationproposalData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue'
import justificationproposalsStoreModule from '../justificationproposalsStoreModule'
import JustificationproposalsViewJustificationproposalInfoCard from './JustificationproposalsViewJustificationproposalInfoCard.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    JustificationproposalsViewJustificationproposalInfoCard,
  },
  setup() {
    const toast = useToast()
    const justificationproposalData = ref(null)

    const JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME = 'app-justificationproposals'

    // Register module
    if (!store.hasModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME)) {
      store.registerModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME, justificationproposalsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME))
        store.unregisterModule(JUSTIFICATIONPROPOSALS_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('app-justificationproposals/fetchJustificationproposal', { id: router.currentRoute.params.id })
      .then((response) => {
        justificationproposalData.value = response.data
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      justificationproposalData,
    }
  },
}
</script>

<style></style>
